<template>
  <div class="enroll_page">
    <div class="enroll_wrapper">
      <div class="enroll_title">{{ data.title }}</div>
      <div class="time">
        <div v-if="data.address">活动地址：{{ data.address }}</div>
        <template v-if="data.enrollStartTime && data.enrollEndTime">
          <div>报名开始时间：{{ data.enrollStartTime }}</div>
          <div>报名截止时间：{{ data.enrollEndTime }}</div>
        </template>
      </div>
      <img v-if="data.cover" class="cover" :src="data.cover" alt="" />
      <div v-html="data.content" class="enroll_content"></div>
    </div>
    <van-button
      v-if="data.isNeedEnroll === '1'"
      :loading="loading"
      class="enroll_btn"
      type="primary"
      size="large"
      :disabled="data.hasEnroll"
      @click="enroll"
      >报名参加</van-button
    >
  </div>
</template>

<script>
import { getVeteransDetail, veteransEnroll } from '@/api/veteransApi'
export default {
  name: 'detail',
  data() {
    return {
      data: {},
      loading: false,
      hasEnroll: false, // 是否已报名 true：已报名  false：未报名
      isNeedEnroll: '0', // 0: 不显示 1：显示
      id: '',
    }
  },
  computed: {},
  watch: {},
  created() {
    this.id = this.$route.query.id
    this.getDetail()
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  methods: {
    // 获取详情信息
    getDetail() {
      let data = { id: this.id }
      getVeteransDetail(data).then(res => {
        this.data = res.data
      })
    },
    // 报名
    enroll() {
      this.loading = true
      let data = { id: this.id }
      veteransEnroll(data)
        .then(res => {
          if (res.code === 200) {
            this.loading = false
            this.$toast({
              type: 'success',
              message: '报名成功！',
              duration: 1000,
              onClose: () => {
                this.$router.go(-1)
              }
            })
            this.getDetail()
          }
        })
        .catch((res) => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.cover {
  width: 100%;
  height: auto;
}
.enroll_page {
  padding: 0.2rem;
  background: #fff;
  position: relative;
  .enroll_wrapper {
    // flex: 1;
    height: 100%;
    overflow-y: auto;
    .enroll_title {
      padding: 0.3rem;
      text-align: center;
      font-size: 0.5rem;
      font-weight: 700;
    }
    .enroll_content {
      padding-top: 0.2rem;
      font-size: 0.3rem;
    }
    .time {
      width: 100%;
      color: #a1a1a1;
      font-size: 0.4rem;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      & > span {
        margin-right: 0.2rem;
      }
    }
    padding-bottom: 1rem;
  }
  .enroll_btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 1.2rem;
  }
}
</style>
